import * as React from "react"
import Layout from "../components/layout"
import "./index.css"
import Header from "../components/header"

const title = "Terms of Use"

const PRIVACY_POLICY_HTML = `
<p><strong>[Emblem] Privacy Notice</strong></p>
<p><strong>Last updated: [ADD DATE]</strong></p>
<p>bluDiagnostics LLC (&ldquo;[Emblem]&rdquo;) is an affiliate of Amazon.com. [Emblem] provides services and products, including through our mobile application (&ldquo;[Emblem] Services&rdquo;). We know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly.</p>
<p>This [Emblem] Privacy Notice governs how we may use and disclose your test results and other health information collected when you register for or use the [Emblem] Services (collectively &ldquo;Personal Health Information&rdquo;). <strong>By using the </strong>[<strong>Emblem</strong>] <strong>Services, you are consenting to the practices described in this </strong>[<strong>Emblem</strong>] <strong>Privacy Notice.</strong> Any other personal information not subject to this [Emblem] Privacy Notice is subject to the <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=468496">Amazon.com Privacy Notice</a>.<br /><br /><strong>How we may use </strong><strong>and disclose </strong><strong>your Personal Health Information</strong><br /><br />We are committed to maintaining your privacy and we take our responsibility for safeguarding your information very seriously. The following categories describe the ways that we may use and disclose your Personal Health Information:</p>

<ul>
<li><u>Communicate with you</u>. We use your Personal Health Information to communicate with you about the [Emblem] Services, such as to provide you with information about your test or test results and to respond to comments and questions.</li>
<li><u>Reporting for compliance purposes</u>. We may use your Personal Health Information in connection with regulatory requirements relating to the safety and efficacy of the [Emblem] Services; to report or share information with health oversight agencies, such as in the context of audits, inspections, and licensure activities; and for other compliance activities.</li>
<li><u>Provide, troubleshoot, and improve </u><u>the </u><u>[Emblem] Services</u>. We use your Personal Health Information to provide the [Emblem] Services, analyze performance, fix errors, and improve the [Emblem] Services. For example, we may use your aggregated or deidentified Personal Health Information to improve the quality and the effectiveness of the [Emblem] Services.</li>
<li><u>Third-Party Service Providers</u>.&nbsp; We may employ other companies and individuals to perform functions on our behalf. These third-party service providers have access to Personal Health Information needed to perform their functions, but may not use it for other purposes. We do not sell Personal Health Information for targeted advertised or marketing purposes, including to third-party service providers.</li>
<li><u>Provide Customer Service.</u> We may use your Personal Health Information to assist with any customer service requests you place relating to the [Emblem] Services. We will limit any Personal Health Information used for customer service to the information needed to assist with your request. This typically includes certain identifiers that enable us to identify you, your [Emblem] account and profile, as well as other information you voluntarily disclose when communicating with the customer service team. We enact security and access controls to safeguard your Personal Health Information from unauthorized access or use.&nbsp;</li>
<li><u>Business Transfers</u>. As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless the customer consents otherwise). Also, in the unlikely event that bluDiagnostics LLC or one of its subsidiaries or substantially all or one of their assets are acquired, customer information will be one of the transferred assets.</li>
<li><u>Protection of Amazon and Others</u>. We release account and other personal information when release is necessary to comply with the law (including to comply with a court or administrative order or lawful process); enforce or apply our [Emblem] Services Terms of Use and other agreements; or protect the rights, property, or health or safety of any person or of Amazon.</li>
<li><u>Research</u>.&nbsp; Only with your explicit consent which will be presented under a separate notice, may we use your Personal Health Information to conduct research or disclose it to researchers as authorized by applicable law. For example, we may use or disclose your Personal Health Information as part of a research study when the research has been approved by an authorized review body that establishes processes to ensure the privacy of your information.</li>
<li>We also may use and disclose your Personal Health Information for other purposes with your consent or authorization, such as when you choose to share test results with third parties.</li>
</ul>

<p><strong>Managing your Personal Health Information</strong></p>
<p>You may make the following types of requests with respect to your Personal Health Information:</p>

<ul>
<li><u>Access</u>. You can access your Personal Health Information through your [Emblem] account and profile.</li>
<li><u>Amendment</u>. If you feel that Personal Health Information in your record is incorrect or incomplete, you may ask us to amend the information by contacting our Customer Service [LINK].</li>
<li><u>Deletion</u>. You can delete your Personal Health Information and your [Emblem] account by going to the Settings page in the [Emblem] mobile application and by performing a hard reset on the [Emblem] reader.</li>
</ul>

<p>There may be limitations on our ability to address your requests, including deletion, consistent with applicable laws, this [Emblem] Privacy Notice and any other applicable terms. All correspondence related to this Privacy Notice must be submitted through our Customer Service [LINK].</p>
<p><br /><strong>Changes to this </strong><strong>[Emblem]</strong><strong> Privacy Notice</strong><br />We reserve the right to change this Notice. These changes will apply to Personal Health Information collected on or after the effective date of the updated Notice. We will post a copy of the revised Notice in the [Emblem] app with the date that any updates were made.<br /><br /><strong>California and Virginia Users</strong><br />Information we collect that is &ldquo;medical information&rdquo; governed by the California Confidentiality of Medical Information Act is not within scope of the California Privacy Rights Act (&ldquo;CPRA&rdquo;). To the extent that we collect personal information that is subject to the CPRA or the Virginia Consumer Data Protection Act in connection with the [Emblem] Services, that information, our practices, and your rights are described <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GC5HB5DVMU5Y8CJ2">here</a>.</p>
<p><strong>FAQS</strong></p>
<p>You can review the following Frequently Asked Questions to learn more about how [Emblem] is designed to protect your privacy.</p>

<ol style="margin:0px 0; padding:0; list-style: decimal inside none;">
<li><strong>Does [Emblem] share Personal Health Information with </strong>third-party service providers<strong>?</strong></li>
<p>No, we do note share or sell your <strong>Personal Health Information </strong>with any third-party service provider without your agreement unless stated in this [Emblem] Privacy Notice, the Emblem terms of use or as required by law.</p>

<li>Do other Amazon businesses have access to Personal Health Information?</li>
<p>Your Personal Health Information is walled-off from other Amazon businesses and is subject to privacy and access safeguards to avoid unwarranted access and disclosure.</p>

<li>How can I delete my <strong>Personal Health Information</strong>?</li>
<p>Subject to applicable law, this [Emblem] Privacy Notice and any other applicable terms, you may request permanent deletion of your [Emblem] account and <strong>Personal Health Information </strong>on AWS cloud services at any time from the Settings page in the app. Once requested all <strong>Personal Health Information </strong>will be automatically deleted. You may also reset the reader and delete all <strong>Personal Health Information </strong>stored on the reader. Only aggregated or deidentified data that cannot be connected back to you will remain to help improve the customer experience.</p>

<li>How long will [Emblem] retain my <strong>Personal Health Information</strong>?</li>
<p>We will retain your <strong>Personal Health Information </strong>until your deletion request or 10 years from the time of collection, whichever is earlier, or as otherwise required by law or pursuant to this [Emblem] Privacy Notice and any other applicable terms.&nbsp; <strong>Personal Health Information </strong>will be securely stored as described during this period.</p>

<li>How is <strong>Personal Health Information</strong> secured?</li>
<p>We use reasonable and appropriate security protocols to secure your <strong>Personal Health Information</strong>, including end-to-end encryption, pseudonymization, and industry standard data storage. All <strong>Personal Health Information </strong>is encrypted in transit, including going to and from AWS cloud services, between your [Emblem] reader, and the mobile application. <strong>Personal Health Information </strong>is also encrypted while being stored securely in AWS cloud services.</p>
<p>We use two layers of pseudonymization to protect connecting your Personal Health Information to your identity.&nbsp; The additional layer of pseudonymization mitigates the risk of unauthorized access or disclosure of your identity. It also creates a single method to sever the connection between your Personal Health Information and any other identifier.</p>

<li>How will we respond if we receive a subpoena?</li>
<p>We are vigilant about our customers' privacy. We will not disclose customer content unless we're required to do so to comply with the law or a binding order of a governmental body. If a governmental body sends us a demand for customer content, we will attempt to redirect the governmental body to request that data directly from the customer. Governmental and regulatory bodies need to follow the applicable legal process to obtain valid and binding orders. We review all orders and object to overbroad or otherwise inappropriate ones. If compelled to disclose customer content to a government body, we will give customers reasonable notice of the demand to allow the customer to seek a protective order or other appropriate remedy unless we are legally prohibited from doing so.</p>
</ol>
`

const privacyNotice = () => (
  <Layout>
    <Header siteTitle={title} />
    <div className="container3">
      <div className="container4">
        <p dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_HTML }} />
      </div>
    </div>
  </Layout>
)

export default privacyNotice
