import * as React from "react"
import { ChevronLeft } from "../Resources/svg/chevronLeft"
import "../css/header.css"
import { navigate } from "gatsby"

interface HeaderProps {
  siteTitle?: string;
  shouldDisplayBackButton?: boolean;
}

const Header = ({ siteTitle, shouldDisplayBackButton = true }: HeaderProps) => (
  <div className="header-container">
    <header className="head-container">
      {shouldDisplayBackButton && 
        (<button
          style={{
            border: "none",
            backgroundColor: "transparent",
            position: "absolute",
            left: 20,
            top: 44,
          }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <ChevronLeft />
        </button>)
      }
      <p className="siteHeader">{siteTitle}</p>
    </header>
  </div>
)

export default Header
